<script>
export default {
    name: 'SimpleLoader',
    props: {
        loadingText: {
            type: String,
            default: 'Lädt...'
        }
    }
}
</script>

<template>
    <div class="d-flex flex-column align-center justify-center h-100">
        <v-progress-circular
            indeterminate
            color="primary"
            size="64"
        ></v-progress-circular>
        <div class="loading-text mt-4">
            {{ loadingText }}
        </div>
    </div>
</template>

<style scoped>
.loading-text {
    font-size: 1.5rem;
    font-weight: 600;
}

.h-100 {
    height: 100dvh;
}
</style>