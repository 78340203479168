import Vue from 'vue'
import Router from 'vue-router'

//Private
import Login from '@/views/public/Login.vue'
import Logout from '@/views/public/Logout.vue'
import Dashboard from '@/views/private/Dashboard.vue'
import ProductionV2 from '@/views/private/production/Production_v2.vue'
import ProductionV3 from '@/views/private/production/Production_v3.vue'
import ProductionItemV2 from '@/views/private/production/ProductionItem_v2.vue'
import PhotoZippoDetails from '@/views/private/production/PhotoZippoZakeke.vue'
import PhotoKeychain from '@/views/private/production/PhotoKeychain.vue'
import PhotoKeychainAmazon from '@/views/private/production/PhotoKeychainAmazon.vue'
import PhotoZippoAmazon from '@/views/private/production/PhotoZippoAmazon.vue'
import BabyCubeDetails from '@/views/private/production/BabyCubeDetails2.vue'
import DateKeyChainDetails from '@/views/private/production/DateKeyChainDetails.vue'
import VictorinoxDetails from '@/views/private/production/VictorinoxDetails.vue'
import PacklistsV2 from '@/views/private/packlist_v2/Packlists.vue'
import PacklistItemsV4 from '@/views/private/packlist_v2/PacklistItemsV4.vue'
import PacklistItemFinder from '@/views/private/packlist_v2/PacklistItemFinder.vue'
import Damaged from '@/views/private/Damaged.vue'
import Retoure from '@/views/private/Retoure.vue'
import Warehouse from '@/views/private/Warehouse.vue'
import ProductionTime from '@/views/private/ProductionTime.vue'
import PackagingRoot from '@/views/private/packaging/PackagingRoot.vue'
import Support from '@/views/private/Support.vue'
import CustomerSupport from '@/views/private/customerSupport/CustomerSupport.vue'
import TimeTracking from '@/views/private/TimeTracking.vue'
import BarcodeGenerator from '@/views/private/BarcodeGenerator.vue'
import PrimeCutOffManager from '@/views/private/primecutoff/PrimeCutOffManager.vue'
import PackageListGenerator from '@/views/private/PackageListGenerator.vue'
import Laser from '@/views/private/LaserHistory.vue'
import PackHistory from '@/views/private/PackHistory.vue'
import InventoryWorth from '@/views/private/InventoryWorth.vue'
import AdminDashboard from '@/views/private/AdminDashboard.vue'
import MuckelMeisterImporter from '@/views/private/importer/MuckelMeisterImporter.vue'
import EtsyMuckelmeisterImporter from '@/views/private/importer/EtsyMuckelmeisterImporter.vue'
import MultiCartShelfManager from '@/views/private/warehouse/MultiCartShelfManager.vue'
import ImporterMonitor from './views/private/importer/ImporterMonitor.vue'

//New Components
import PacklistItemsV5 from '@/views/private/packlist_v2/PacklistItemsV5.vue'
import RetoureV2 from '@/views/private/Retoure/RetoureV2.vue'
import PacklistRoot from '@/views/private/EndlessPacklist/PacklistRoot.vue'
import ShippingRulesManager from '@/views/private/ShippingRulesManager.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/auth/login",
      name: "Login",
      component: Login
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: Logout
    },
    
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/damaged',
      name: 'Fehlproduktionen',
      component: Damaged
    },
    {
      path: '/retoure',
      name: 'Retouren',
      component: RetoureV2
    },
    {
      path: '/v2/production',
      name: 'ProductionV2',
      component: ProductionV2
    },
    {
      path: '/v3/production',
      name: 'ProductionV3',
      component: ProductionV3
    },
    {
      path: '/laser',
      name: 'Laser',
      component: Laser
    },
    {
      path: '/packhistory',
      name: 'PackHistrory',
      component: PackHistory
    },
    {
      path: '/production/:name',
      name: 'ProductionItem',
      component: ProductionItemV2
    },
    
    {
      path: '/production/photozippo/details',
      name: 'PhotoZippoDetails',
      component: PhotoZippoDetails
    },
    {
      path: '/production/keychain_photo/details',
      name: 'PhotoKeychain',
      component: PhotoKeychain
    },
    {
      path: '/production/keychain_picture/details',
      name: 'PhotoKeychainAmazon',
      component: PhotoKeychainAmazon
    },
    {
      path: '/production/photozippo_amz/details',
      name: 'PhotoZippoAmazon',
      component: PhotoZippoAmazon
    },
    {
      path: '/production/babycube/details',
      name: 'BabyCubeDetails',
      component: BabyCubeDetails
    },
    {
      path: '/production/datekeychain/details',
      name: 'DateKeyChainDetails',
      component: DateKeyChainDetails
    },
    {
      path: '/production/victorinox/details',
      name: 'VictorinoxDetails',
      component: VictorinoxDetails
    },
    {
      path: '/packlistsV2',
      name: 'Packlists V2',
      alias: '/',
      component: PacklistsV2
    },
    {
      path: '/packlistV2/:id',
      name: 'Packlist V2',
      component: PacklistItemsV4
    },
    {
      path: '/packlistV3/:id',
      name: 'Packlist Details V3',
      component: PacklistRoot
    },
    {
      path: '/packlistFinder',
      name: 'Packlist Finder',
      component: PacklistItemFinder
    },
    {
      path: '/warehouse',
      name: 'Warehouse',
      component: Warehouse
    },
    {
      path: '/warehouse/multicart',
      name: 'MultiCartShelfManager',
      component: MultiCartShelfManager
    },
    {
      path: '/productiontime',
      name: 'ProductionTime',
      component: ProductionTime
    },
    {
      path: '/cartons',
      name: 'Cartons',
      component: PackagingRoot
    },
    {
      path: '/support',
      name: 'Support',
      component: Support
    }, 
    {
      path: '/customerSupport',
      name: 'Customer Support',
      component: CustomerSupport
    },
    {
      path: '/zeiterfassung',
      name: 'Zeiterfassung',
      component: TimeTracking
    },
    {
      path: '/barcode',
      name: 'Barcode Generator',
      component: BarcodeGenerator
    },
    {
      path: '/primecutoff',
      name: 'Prime Cut-Off Manager',
      component: PrimeCutOffManager
    },
    {
      path: '/packagelist',
      name: 'Paketübergabeliste',
      component: PackageListGenerator
    },
    {
      path: '/inventory',
      name: 'Inventory Worth',
      component: InventoryWorth
    },
    {
      path: '/adminDashboard',
      name: 'Admin Dashboard',
      component: AdminDashboard
    }, 
    {
      path: '/importer/muckelmeister',
      name: 'MuckelMeister Importer',
      component: MuckelMeisterImporter
    },
    {
      path: '/importer/etsymuckelmeister',
      name: 'Etsy MuckelMeister Importer',
      component: EtsyMuckelmeisterImporter
    },
    {
      path: '/importer',
      name: 'importer',
      component: ImporterMonitor,
      meta: { requiresAuth: true }
    },
    {
      path: '/shipping-rules',
      name: 'Versandprofilregeln',
      component: ShippingRulesManager,
      meta: { requiresAuth: true }
    }
  ]
})
