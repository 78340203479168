import io from 'socket.io-client';

class MessageHandler {
  constructor() {
    this.socket = null;
    this.isConnected = false;

    this.setupSocketConnection();
    this.registerWebsocketEvents();
  }

  /**
   * Setup socket connection
   */
  async setupSocketConnection() {
    try {
      // Configure socket with reconnection options
      this.socket = io('/internal-messages', {
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        timeout: 10000
      });

      // Add connect event handler
      this.socket.on('connect', () => {
        this.isConnected = true;
      });

      // Add disconnect event handler
      this.socket.on('disconnect', () => {
        this.isConnected = false;
      });

    } catch (error) {
      console.error('Error setting up WebSocket connection:', error);
      this.isConnected = false;
    }
  }

  /**
   * Handle message events
   * @param {*} message 
   */
  handleMessageEvents(message) {
    console.log('handleMessageEvents', message);
  }

  /**
   * Register websocket events
   */
  async registerWebsocketEvents() {
    console.log('Registering websocket events');

    this.socket.on('message', (message) => {
      this.handleMessageEvents(message);
    });
  }
}

export default new MessageHandler();