<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import AuthService from '@/services/AuthService'
import router from './router'
import EventBus from '@/event-bus'

const IDLE_TIMEOUT = 30 * 60 * 1000 // Konvertiere zu Millisekunden
const LAST_ACTIVITY_KEY = 'lastUserActivity'

export default {
  name: "app",
  
  data() {
    return {
      events: ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'],
      isAuthenticated: false,
      user: null,
      checkInterval: null,
      remainingTime: 0, // Verbleibende Zeit in Sekunden
      countdownInterval: null, // Intervall für den Countdown
    }
  },

  async created() {
    try {
      const response = await AuthService.getUser();
      this.user = response.data;
      this.isAuthenticated = !!this.user;

      EventBus.$on('user-logged-in', this.handleLogin);
      EventBus.$on('user-logged-out', this.handleLogout);

      if (this.isAuthenticated) {
        this.checkIdleStatus();
        await this.setupIdleCheck();
      }

      // Prüfe beim Aufwachen/Fokussieren
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
      window.addEventListener('focus', this.checkIdleStatus);
    } catch (error) {
      console.error('Fehler beim Initialisieren:', error);
      this.isAuthenticated = false;
      this.user = null;
    }
  },

  beforeDestroy() {
    this.cleanupIdleCheck();
    this.cleanupCountdown(); // Countdown Intervall entfernen
    EventBus.$off('user-logged-in', this.handleLogin);
    EventBus.$off('user-logged-out', this.handleLogout);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    window.removeEventListener('focus', this.checkIdleStatus);
  },

  methods: {
    async handleLogin() {
      try {
        const response = await AuthService.getUser();
        this.user = response.data;
        this.isAuthenticated = !!this.user;
        await this.setupIdleCheck();
      } catch (error) {
        console.error('Fehler beim Login:', error);
        this.isAuthenticated = false;
        this.user = null;
      }
    },

    handleLogout() {
      this.isAuthenticated = false;
      this.user = null;
      this.cleanupIdleCheck();
      this.cleanupCountdown(); // Countdown Intervall entfernen
      localStorage.removeItem(LAST_ACTIVITY_KEY);
      localStorage.removeItem("lastTimestamp");
    },

    async setupIdleCheck() {
      this.cleanupIdleCheck();
      this.startCountdown(); // Starte Countdown beim Setup
      
      // Initialen Timestamp setzen
      localStorage.setItem(LAST_ACTIVITY_KEY, Date.now().toString());
      
      // Event Listener für Benutzeraktivität
      this.events.forEach(event => {
        window.addEventListener(event, this.updateLastActivity);
      });

      // Regelmäßige Überprüfung
      this.checkInterval = setInterval(this.checkIdleStatus, 10000);
    },

    updateLastActivity() {
      if (!this.isAuthenticated) return;
      this.checkIdleStatus();
      localStorage.setItem(LAST_ACTIVITY_KEY, Date.now().toString());
      this.resetCountdown(); // Reset Countdown bei Benutzeraktivität
    },

    handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        this.checkIdleStatus();
      }
    },

    checkIdleStatus() {
      if (!this.isAuthenticated) return;

      const lastActivity = parseInt(localStorage.getItem(LAST_ACTIVITY_KEY) || '0');
      const now = Date.now();
      
      if (now - lastActivity > IDLE_TIMEOUT) {
        console.log('Inaktivitäts-Timeout erreicht, führe Logout durch...');
        this.performLogout();
      }
    },

    cleanupIdleCheck() {
      if (this.checkInterval) {
        clearInterval(this.checkInterval);
        this.checkInterval = null;
      }

      this.events.forEach(event => {
        window.removeEventListener(event, this.updateLastActivity);
      });
      this.cleanupCountdown(); // Countdown Intervall entfernen
    },

    cleanupCountdown() {
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
        this.countdownInterval = null;
      }
    },

    startCountdown() {
      this.remainingTime = IDLE_TIMEOUT / 1000; // Initialisiere verbleibende Zeit in Sekunden
      this.countdownInterval = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
          this.emitRemainingTime(); // Sende verbleibende Zeit an Toolbar
        } else {
          this.cleanupCountdown(); // Stoppe Countdown wenn Zeit abgelaufen
        }
      }, 1000);
      this.emitRemainingTime(); // Initiales Senden der Zeit
    },

    resetCountdown() {
      this.remainingTime = IDLE_TIMEOUT / 1000; // Setze verbleibende Zeit zurück
      this.emitRemainingTime(); // Sende aktualisierte Zeit an Toolbar
    },

    emitRemainingTime() {
      const minutes = Math.floor(this.remainingTime / 60);
      const seconds = Math.floor(this.remainingTime % 60);
      EventBus.$emit('idle-timer-update', { minutes, seconds }); // Sende Zeit über EventBus
    },

    async performLogout() {
      try {
        await AuthService.logout();
        EventBus.$emit('user-logged-out');
        router.push('/auth/login');
      } catch (error) {
        console.error('Fehler beim Logout:', error);
      }
    }
  },

  watch: {
    // Überwache den Router für Änderungen und den Auth-Status
    '$route': {
      immediate: true,
      async handler(to) {
        if (to.path !== '/auth/login') {
          try {
            const response = await AuthService.getUser();
            this.user = response.data;
            this.isAuthenticated = !!this.user;
            
            if (this.isAuthenticated && !this.checkInterval) {
              console.log('Route geändert, prüfe Timer...');
              await this.setupIdleCheck();
            }
          } catch (error) {
            console.error('Fehler beim Abrufen der User-Daten:', error);
            this.isAuthenticated = false;
            this.user = null;
          }
        }
      }
    }
  }
};
</script>

<style>
@media (min-width: 960px) and (max-width: 961px) {
  h1 {
    font-size: 1em;
    line-height: 15px;
  }
  h2,
  h3 {
    font-size: 15px;
    line-height: 15px;
  }

  p {
    font-size: 12px;
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
    margin-inline-end: 0px !important;
    margin-inline-start: 0px !important;
    line-height: 18px !important;
  }

  .pa-3.v-card.v-sheet.theme--light > p {
    font-size: 1.4em !important;
  }

  .pa-3.v-card.v-sheet.theme--light > p > img {
    max-width: 170px !important;
  }
  .v-progress-linear.v-progress-linear--striped.theme--light {
    height: 10px !important;
    font-size: 10px !important;
  }
  .v-card__title {
    padding: 5px !important;
  }
  .v-card__text {
    padding: 5px !important;
  }
  .container {
    width: 100% !important;
  }
  button.v-btn.v-btn--text.theme--light.v-size--default.success--text {
    max-width: 48px !important;
    font-size: 10px !important;
  }
  button.v-btn.v-btn--text.theme--light.v-size--default.error--text {
    max-width: 48px;
    font-size: 10px;
  }

  span.v-btn__content {
    font-size: 12px !important;
    margin-right: 12px;
  }
}
</style>