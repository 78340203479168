
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css' 
Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },

  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#86D2DC',
        secondary: '#78909c',
      },
      dark: {
        dark: true,
        primary: '#86D2DC',
        secondary: '#78909c',
      },

    }
  }

});
