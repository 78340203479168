<script>
import MessageHandler from '@/handlers/MessageHandler';

export default {
    name: 'PacklistMessage',
    data() {
        return {
            show: false,
            timeout: null
        }
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    methods: {
        /**
         * Starts the message lifetime timer
         * @param lifetime - The lifetime of the message in milliseconds
         */
        startMessageLifetime(lifetime) {
            //Clear timeout if it exists
            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            //Start the message lifetime timer
            if (lifetime && lifetime !== -1) {
                this.timeout = setTimeout(() => {
                    this.show = false;
                }, lifetime);
            }
        },
        /**
         * Handle form submit
         */
        handleAction(action) {
            //Handling for form submit
            if (this.data.type === 'form') {
                this.data.formItems.forEach(item => {
                    action.data[item.label] = item.value;
                });

                MessageHandler.sendMessage(action.messageType, action.data);
            }
        }
    },
    watch: {
        data: {
            handler(newData) {
                if (newData) {
                    this.show = true;

                    //Start the message lifetime timer
                    this.startMessageLifetime(this.data.timing.lifetime);
                }
            },
            deep: true
        },
        show: {
            handler(newVal) {
                if (!newVal) {
                    this.data = null;
                    this.$emit('close-message');
                }
            }
        }
    }
}
</script>

<template>
    <div v-if="show">
        <!-- Type: message -->
        <v-bottom-sheet 
            v-if="data && data.type === 'message'" 
            max-width="1000"
            v-model="show"
        >
            <v-sheet
                class="pa-2"
                :color="data.style.severity"
            >
                <h1
                    :class="`${data.style.textColor}--text`"
                >
                    {{ data.title }}
                </h1>

                <v-divider></v-divider>

                <v-card-text
                    class="pa-1"
                    :class="`${data.style.textColor}--text`"
                    v-html="data.message"
                ></v-card-text>
            </v-sheet>
        </v-bottom-sheet>

        <!-- Type: alert -->
        <v-dialog 
            max-width="1000"
            v-if="data && data.type === 'alert'" 
            v-model="show"
        >
            <v-alert
                class="ma-0"
                :color="data.style.severity"
            >
                <h1
                    :class="`${data.style.textColor}--text`"
                >
                    {{ data.title }}
                </h1>

                <div
                    class="pa-1"
                    :class="`${data.style.textColor}--text`"
                    v-html="data.message"
                ></div>

                <v-divider></v-divider>

                <v-card-actions
                    class="d-flex justify-end"
                >
                    <v-btn
                        v-if="data.reading.isDismissible"
                        @click="show = false"
                    >
                        <v-icon>mdi-check</v-icon>
                        Verstanden
                    </v-btn>
                </v-card-actions>
            </v-alert>
        </v-dialog>
        
        <!-- Type: modal -->
        <v-dialog
            max-width="1000"
            class="pa-2" 
            v-if="data && data.type === 'modal'" 
            v-model="show"
        >
            <v-card>
                <v-card-title
                    :class="`${data.style.severity} ${data.style.textColor}--text`"
                >{{ data.title }}</v-card-title>

                <v-divider></v-divider>

                <v-card-text
                    class="pa-4"
                    v-html="data.message"
                ></v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        v-if="data.reading.isDismissible"
                        @click="show = false"
                    >
                        <v-icon>mdi-close</v-icon>
                        Verstanden
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Type: form -->
        <v-dialog
            max-width="1000"
            class="pa-2" 
            v-if="data && data.type === 'form'" 
            v-model="show"
        >
            <v-card>
                <v-card-title
                    :class="`${data.style.severity} ${data.style.textColor}--text`"
                >{{ data.title }}</v-card-title>

                <v-divider></v-divider>

                <v-card-text
                    v-if="data.formItems && data.formItems.length > 0"
                    class="pa-4"
                >
                    <v-form>
                        <div v-for="(item, index) in data.formItems" :key="index">
                            <label :for="item.label">{{ item.label }}</label>
                            <!-- Text input field -->
                            <v-text-field
                                v-if="item.type === 'text'"
                                v-model="item.value"
                                outlined
                                dense
                                class="mb-3"
                            ></v-text-field>
                            
                            <!-- Select dropdown -->
                            <v-select
                                v-else-if="item.type === 'select'"
                                v-model="item.value"
                                :items="item.options"
                                outlined
                                dense
                                class="mb-3"
                            ></v-select>

                            <!-- Textarea for multiline text -->
                            <v-textarea
                                v-else-if="item.type === 'textarea'"
                                v-model="item.value"
                                outlined
                                dense
                                class="mb-3"
                                :rows="item.rows || 3"
                                :auto-grow="item.autoGrow || false"
                            ></v-textarea>

                            <!-- Number input -->
                            <v-text-field
                                v-else-if="item.type === 'number'"
                                v-model="item.value"
                                type="number"
                                outlined
                                dense
                                class="mb-3"
                                :min="item.min"
                                :max="item.max"
                                :step="item.step || 1"
                            ></v-text-field>

                            <!-- Checkbox -->
                            <v-checkbox
                                v-else-if="item.type === 'checkbox'"
                                v-model="item.value"
                                :label="item.checkboxLabel || ''"
                                dense
                                class="mb-3"
                                hide-details
                            ></v-checkbox>

                            <!-- Switch -->
                            <v-switch
                                v-else-if="item.type === 'switch'"
                                v-model="item.value"
                                :label="item.switchLabel || ''"
                                dense
                                class="mb-3"
                                hide-details
                            ></v-switch>

                            <!-- Radio button group -->
                            <v-radio-group
                                v-else-if="item.type === 'radio'"
                                v-model="item.value"
                                class="mb-3"
                                row
                            >
                                <v-radio
                                    v-for="option in item.options"
                                    :key="option.value"
                                    :label="option.text"
                                    :value="option.value"
                                ></v-radio>
                            </v-radio-group>

                            <!-- Slider -->
                            <v-slider
                                v-else-if="item.type === 'slider'"
                                v-model="item.value"
                                :min="item.min || 0"
                                :max="item.max || 100"
                                :step="item.step || 1"
                                thumb-label
                                class="mb-3"
                            ></v-slider>

                            <!-- File input -->
                            <v-file-input
                                v-else-if="item.type === 'file'"
                                v-model="item.value"
                                outlined
                                dense
                                class="mb-3"
                                :accept="item.accept || ''"
                                :multiple="item.multiple || false"
                                :chips="item.chips || false"
                                :show-size="item.showSize || true"
                            ></v-file-input>

                            <!-- Date picker -->
                            <v-menu
                                v-else-if="item.type === 'date'"
                                ref="dateMenu"
                                v-model="item.menuOpen"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="item.value"
                                        readonly
                                        outlined
                                        dense
                                        class="mb-3"
                                        v-bind="attrs"
                                        v-on="on"
                                        prepend-icon="mdi-calendar"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="item.value"
                                    @input="item.menuOpen = false"
                                ></v-date-picker>
                            </v-menu>

                            <!-- Autocomplete -->
                            <v-autocomplete
                                v-else-if="item.type === 'autocomplete'"
                                v-model="item.value"
                                :items="item.options"
                                outlined
                                dense
                                class="mb-3"
                                :multiple="item.multiple || false"
                                :chips="item.chips || false"
                                :small-chips="item.smallChips || false"
                            ></v-autocomplete>

                            <!-- Color picker -->
                            <v-color-picker
                                v-else-if="item.type === 'color'"
                                v-model="item.value"
                                class="mb-3"
                                :swatches="item.swatches || false"
                                :mode="item.mode || 'hexa'"
                                :elevation="1"
                            ></v-color-picker>

                            <!-- Time picker -->
                            <v-menu
                                v-else-if="item.type === 'time'"
                                ref="timeMenu"
                                v-model="item.menuOpen"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="item.value"
                                        readonly
                                        outlined
                                        dense
                                        class="mb-3"
                                        v-bind="attrs"
                                        v-on="on"
                                        prepend-icon="mdi-clock-time-four-outline"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-model="item.value"
                                    @input="item.menuOpen = false"
                                    format="24hr"
                                ></v-time-picker>
                            </v-menu>
                        </div>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-row
                        class="d-flex justify-end"
                    >
                        <v-btn
                            v-if="data.reading.isDismissible"
                            @click="show = false"
                            color="error"
                            class="mr-2"
                        >
                            <v-icon>mdi-close</v-icon>
                            Close
                        </v-btn>
                        <v-btn
                            v-for="action in data.actions"
                            :key="action.label"
                            @click="handleAction(action)"
                            color="success"
                        >
                            <v-icon>mdi-close</v-icon>
                            {{ action.label }}
                        </v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
